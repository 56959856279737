$time: 500ms;
$delay: $time/2;
$mode: cubic-bezier(0.17, 0.67, 0.55, 1.43);
@keyframes heartbeat {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.react-3d-carousel {
  .slider-container {
    .slider-content {
      .slider-single {
        .slider-single-content {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: relative;
          left: 0;
          top: 0;
          width: 100%;
          box-shadow: none;
          transition: $time $mode;
          transform: scale(0);
          opacity: 0;
          img {
          }
        }
        &.preactivede2 {
          z-index: 1;
          .slider-single-content {
            opacity: 0.1;
            transform: translateX(-220%) scale(1);
          }
        }
        &.preactive2 {
          z-index: 1;
          .slider-single-content {
            opacity: 0.5;
            transform: translateX(-110%) scale(1);
          }
        }
        &.proactive2 {
          z-index: 1;
          .slider-single-content {
            opacity: 0.5;
            transform: translateX(110%) scale(1);
          }
        }
        &.proactivede2 {
          z-index: 1;
          .slider-single-content {
            opacity: 0.1;
            transform: translateX(220%) scale(1);
          }
        }
        &.active2 {
         
          .slider-left,
          .slider-right {
            display: block;
          }

          z-index: 2;
          .slider-single-content {
            opacity: 1;
            transform: translateX(0%) scale(1);
          }
        }
      }
    }
    .slider-left2 {
      position: absolute;
      z-index: 3;
      right: 100%;
      margin-right: 5px;
      padding: 20px 15px;
      height: 100%;
      opacity: 0.1;
      cursor: pointer;
      div {
        position: absolute;
        top: 50%;
        left: 0%;
        margin-top: -20px;
        color: #ffffff;
        transform: translateY(-50%);
        padding: 10px 10px;
        width: 40px;
      }
    }
    .slider-right2 {
      position: absolute;
      z-index: 3;
      left: 100%;
      padding: 20px 15px;
      height: 100%;
      opacity: 0.1;
      cursor: pointer;
      div {
        position: absolute;
        top: 50%;
        left: 0%;
        margin-top: -20px;
        color: #ffffff;
        transform: translateY(-50%);
        padding: 10px 10px;
        width: 40px;
      }
      margin-left: -2px;
    }
    .slider-left1-noborders {
      position: absolute;
      display: none;
      z-index: 3;
      right: 101%;
      margin-right: 5px;
      padding: 20px 15px;
      height: 100%;
      cursor: pointer;
      div {
        position: absolute;
        top: 50%;
        left: 0%;
        margin-top: -20px;
        color: #ffffff;
        transform: translateY(-50%);
        padding: 10px 10px;
      }
    }
    .slider-right1-noborders {
      display: none;
      position: absolute;
      z-index: 3;
      left: 101%;
      padding: 20px 15px;
      height: 101%;
      cursor: pointer;
      div {
        position: absolute;
        top: 50%;
        left: 0%;
        margin-top: -20px;
        color: #ffffff;
        transform: translateY(-50%);
        padding: 10px 10px;
      }
      margin-left: -2px;
    }
    .slider-disabled {
      div {
        display: none;
      }
    }
  }
}
